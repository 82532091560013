import React, { useState, useRef, useEffect } from "react";

import NavBanner from "../NavBanner/NavBanner";
import "./NavDesk.css";
export default function DeskNav({ setNavExpand }) {

  const [navNumber, setNavNumber] = useState(0);
  function handleMouseOver(e) {
    const navNo = Number(e.currentTarget.getAttribute("data-number"));

    // console.log(navCollapse);

    setNavNumber((prevNumber) => {
      if (navNo === prevNumber) {
        return 0;
      }
      return navNo;
    });
  }

  let menuRef = useRef();

  useEffect(() => {

    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setNavNumber(0)
      }
    }

    document.addEventListener("mousedown", handler)
    return () => {
      document.removeEventListener("mousedown", handler)
    }
  }, [])


  return (
    <div className="nav__menu" ref={menuRef}>
      <a href="/">
        <button
          className="nav__link"
          onClick={handleMouseOver}
          data-number="0"
        >
          Home
        </button>
      </a>
      <button className={`nav__link ${navNumber === 1 ? 'rotate' : 'nav_arrow'}`} onClick={handleMouseOver} data-number="1">
        Products
      </button>
      {navNumber === 1 ? (
        <NavBanner
          setNavNumber={setNavNumber}
          setNavExpand={setNavExpand}
          row1={{
            title: "Products",
            grid: [
              {
                name: "AUSIS",
                info: "AI UW System",
                subGrid: [
                  { title: "Life Underwriting", list: ["Individual", "Group"] },
                  {
                    title: "Health Underwriting",
                    list: ["Individual", "Family"],
                  },
                  {
                    title: "Alternative Underwriting",
                    list: ["Financial", "Health"],
                  },
                  {
                    title: "SME Underwriting",
                    list: ["Business"],
                  },
                  {
                    title: "Travel Underwriting",
                    list: ["Travel"],
                  },
                ],
                RouteLink: "/products/ausis"
              },
              {
                name: "ALFRED",
                info: "AI Claims Platform",
                subGrid: [
                  {
                    title: "Life Claims",
                    list: ["Accidental", "Death", "Disability", "Maturity"],
                  },
                  {
                    title: "Health Claims",
                    list: [
                      "Reimbursement",
                      "OPD",
                      "Provider Network",
                      "Tariff System",
                      "TPA Network",
                    ],
                  },
                  {
                    title: "Auto Claims",
                    list: [
                      "Pre-Inspection",
                      "Damage Detection",
                      "Estimation",
                      "Investigation",
                      "Invoice Processing",
                    ],
                  },
                  {
                    title: "Property Claims",
                    list: [
                      "Pre-Inspection",
                      "Damage Detection",
                      "Estimation",
                      "Investigation",
                    ],
                  },
                  {
                    title: "Commercial Claims",
                    list: ["Document Process", "Estimation"],
                  },
                ],
                RouteLink: "/products/alfred"
              },
              {
                name: "ASPIRE",
                info: "360 Intelligence Platform",
                RouteLink: "/products/aspire",
                subGrid: [
                  {
                    title: 'ASPIRE Health',
                    list: [
                      'Individual Insurance',
                      'Customer',
                    ],
                  },
                  {
                    title: 'ASPIRE Commercial',
                    list: [
                      'Commercial Insurance',
                      'Customer',
                    ],
                  },
                  {
                    title: 'ASPIRE Employee 360',
                    list: [
                      'Individual Employee',
                      'Employee Health',
                    ],
                  },
                ]
              },
              {
                name: "MIO",
                info: "Sales, Pos, Marketing Servicing",
                RouteLink: "/products/mio",
                subGrid: [
                  {
                    title: 'MiO Sales',
                  },
                  {
                    title: 'MiO D2C (Broker)',
                  },
                  {
                    title: 'MiOBanca',
                  },
                  {
                    title: 'MiO Support',
                  }
                ],
              },
              {
                name: "INFRD",
                info: "Insurance API Cloud Platform",
                RouteLink: "/products/infrd",
                subGrid: [
                  {
                    title: 'INFRD - Developer Platform',
                    list: [
                      'APIs',
                      'Solutions',
                      'Payment',
                      'PIVC, Video KYC, Extractor'
                    ]
                  },
                ],
              },
              {
                name: "PRODX",
                info: "Design & Distribution Platform",
                RouteLink: "/products/prodx",
                subGrid: [
                  {
                    title: 'Prodx Design',
                    list: [
                      'Life',
                      'Health',
                      'Commercial',
                      'Micro',
                      'Sachet',
                    ],
                  },
                  {
                    title: ' Prodx Distribution',
                    list: [
                      'B2B2C Distribution',
                    ],
                  },
                ]
              },
            ],
          }}
          row2={{
            title: "OCR/ICR Engine",
            grid: [
              { name: "Early Claims", info: "Medical & Health Claims", RouteLink: "/products/early-claims" },
              {
                name: "Pre Issuance Verification Calls(PIVC)",
                info: "Live Video & Audio via Speech Recognition",
                RouteLink: '/products/preissuance-verification'
              },
              {
                name: "SecondQuote Engine",
                info: "Instant Quote for Policy Products",
                RouteLink: '/products/secondQuote-engine'
              },
              {
                name: "Inspection systems",
                info: "End to end Risk Inspection System",
                RouteLink: '/products/inspection-systems'
              },
              {
                name: "OCR/ICR",
                info: "Enhance Process Experience",
                RouteLink: '/products/ocr-icr'
              },
              {
                name: "Provider Network",
                info: "Provider Network Solutions",
                RouteLink: '/products/provider-network'
              },
              {
                name: "Fraud Intelligence System",
                info: "Fraud Intelligence Solutions",
                RouteLink: '/products/fraud-intelligence-system'
              },
            ],
          }}
        />
      ) : (
        ""
      )}
      <a href="/cloud-api">
        <button className='nav__link'>
          Cloud API
        </button>
      </a>
      {/* {navNumber === 2 ? (
        <NavBanner
          setNavNumber={setNavNumber}
          setNavExpand={setNavExpand}
          row1={{
            title: "Cloud API Platform",
            grid: [
              { name: "Overview", info: "Holistic Insurance & Healthcare APIs", RouteLink: "/cloud-api" },
              {
                name: "Health API",
                info: "Healthcare & Wellness", RouteLink: "/cloud-api"
              },
              {
                name: "Insurance API",
                info: "Insurance and Finance", RouteLink: "/cloud-api"
              },
              {
                name: "Distribution API",
                info: "Partners, Brokers and Distributors", RouteLink: "/cloud-api"
              },
              {
                name: "OCR API",
                info: "Document reading, Matching and Analysis", RouteLink: "/cloud-api"
              },
              {
                name: "Sales API",
                info: "Sales & Marketing Solutions", RouteLink: "/cloud-api"
              },
              {
                name: "Risk API",
                info: "Risk & Fraud Detection", RouteLink: "/cloud-api"
              },
              {
                name: "Govt API",
                info: "Linking Systems and Data Solution", RouteLink: "/cloud-api"
              },
              {
                name: "+400 APIs",
                info: 'Know more',
              },
            ],
          }}
        />
      ) : (
        ""
      )} */}
      <button className={`nav__link ${navNumber === 3 ? 'rotate' : 'nav_arrow'}`} onClick={handleMouseOver} data-number="3">
        Solutions
      </button>
      {navNumber === 3 ? (
        <NavBanner
          setNavNumber={setNavNumber}
          setNavExpand={setNavExpand}
          row1={{
            title: "Solutions",
            grid: [
              { name: "Life Insurance", info: "400+ APIs and 8+ Core platforms", RouteLink: "" },
              {
                name: "Health Insurance",
                info: "AI Driven 360-degree platform for Health Insurance",
                RouteLink: "/solutions/health-insurance"
              },
              {
                name: "SME & Commercial Insurance",
                info: "Aspire business enabling SME insurance",
                RouteLink: "/solutions/sme-insurance"
              },
              {
                name: "Distributors & Brokers",
                info: "Self- service API driven SaaS platform",
                RouteLink: "/solutions/distributors-brokers"
              },
              {
                name: "Sales & Marketing",
                info: "AI Based MiO Sales redefines Sales & Marketing",
                RouteLink: "/solutions/sales-marketing"
              },
              {
                name: "Reinsurance",
                info: "ALFRED AI Health Claims Solution",
                RouteLink: "/solutions/reinsurance"
              },
              {
                name: "TPAs",
                info: "360 AI Health Platform for TPA & Hospitals",
                RouteLink: "/solutions/third-party-administrator"
              },
              {
                name: "Hospitals & Clinics",
                info: "360 AI Health Platform for Clinics & Hospitals",
                RouteLink: "/solutions/hospitals-clinics"
              },
              {
                name: "Micro Insurance",
                info: "API & Risk assessment platform",
                RouteLink: "/solutions/micro-insurance"
              },
              {
                name: "Group Health",
                info: "ASPIRE Business for real-time Group Health",
                RouteLink: "/solutions/group-health"
              },
              {
                name: "Enterprise & Fintech",
                info: "SaaS based and Data driven platform",
                RouteLink: "/solutions/enterprise-fintech"
              },
              {
                name: "OEM/Dealership Management & CRM System",
                info: "SaaS based and Data driven platform",
                RouteLink: "/solutions/oem-dealership-management-crm-system"
              },
              {
                name: "Garage Management System",
                info: "SaaS based and Data driven platform",
                RouteLink: "/solutions/garage-management"
              },
              {
                name: "Insurance in a Box",
                info: "SaaS based and Data driven platform",
                RouteLink: "/solutions/insurance-in-a-box"
              },
            ],
          }}
        />
      ) : (
        ""
      )}
      <button className={`nav__link ${navNumber === 4 ? 'rotate' : 'nav_arrow'}`} onClick={handleMouseOver} data-number="4">
        Resource
      </button>
      {navNumber === 4 ? (
        <NavBanner
          setNavNumber={setNavNumber}
          setNavExpand={setNavExpand}
          row1={{
            title: "Resource",
            grid: [
              {
                name: "Blogs",
                info: "News, Tips and Insights from across Industry",
                RouteLink: "/resources/blogs"
              },
              {
                name: "Infographics",
                info: "World of InsurTech in infographics",
                RouteLink: "/resources/infographics"
              },
              {
                name: "Podcasts",
                info: "Hear the Expert Talk",
                RouteLink: "/resources/podcasts"
              },
              {
                name: "Reports",
                info: "Read our Featured Research and Data Insights",
              },
              {
                name: "Webinars",
                info: "Conversation which Matters",
              },
             
              {
                name: "Video",
                info: "Explore Insurtech Videos",
              },
            ],
          }}
        />
      ) : (
        ""
      )}
      <button className={`nav__link ${navNumber === 5 ? 'rotate' : 'nav_arrow'}`} onClick={handleMouseOver} data-number="5">
        Covid 19
      </button>
      {navNumber === 5 ? (
        <NavBanner
          setNavNumber={setNavNumber}
          setNavExpand={setNavExpand}
          row1={{
            title: "Covid 19 tools",
            grid: [
              { name: "Video Communication", info: "Video Solutions to combat Covid 19" },
              {
                name: "Remote Sales",
                info: "Sales and Marketing Solutions",
              },
              {
                name: "Employee Engagement",
                info: "Education and Training",
              },
              {
                name: "Communication",
                info: "Multichannel Communication",
              },
              {
                name: "Content",
                info: "Covid-19 Data Hub to access up-to-date resources",
              },
              // {
              //   name: "Cowin App",
              //   info: "Tag line",
              // },
              {
                name: "CRM",
                info: "Intelligent CRM",
              },
              {
                name: "Lead management",
                info: "Smart Lead Scoring",
              },
              {
                name: "Video app",
                info: "Video enabled solutions are the future",
              },
            ],
          }}
        />
      ) : (
        ""
      )}
      <button className={`nav__link ${navNumber === 6 ? 'rotate' : 'nav_arrow'}`} onClick={handleMouseOver} data-number="6">
        Growth
      </button>
      {navNumber === 6 ? (
        <NavBanner
          setNavNumber={setNavNumber}
          setNavExpand={setNavExpand}
          row1={{
            title: "Customer Growth",
            grid: [
              { name: "Overview", info: "Connect, Network and participate at Artivatic Forum", RouteLink: "/growth" },
              {
                name: "Webinar",
                info: "On-demand webinars with industry influencers & product experts",
                RouteLink: "/growth"
              },
              {
                name: "Academy",
                info: "Online Business Training ",
                RouteLink: "/growth"
              },
              {
                name: "Events",
                info: "Upcoming Events, Trade Shows and Webinars",
                RouteLink: "/growth"
              },
              {
                name: "Videos",
                info: "Watch featured interview of our experts",
                RouteLink: "/growth"
              },
            ],
          }}
        />
      ) : (
        ""
      )}
      <button className={`nav__link ${navNumber === 7 ? 'rotate' : 'nav_arrow'}`} onClick={handleMouseOver} data-number="7">
        Company
      </button>
      {navNumber === 7 ? (
        <NavBanner
          setNavNumber={setNavNumber}
          setNavExpand={setNavExpand}
          row1={{
            title: "Company",
            grid: [
              { name: "About Us ", info: "Building next-gen InsurTech", RouteLink: "/about-us" },
              {
                name: "Partners",
                info: "Empowering our Partners",
                RouteLink: "/partners"
              },
              {
                name: "Board and Investors",
                info: "Key People",
                RouteLink: "/investors"
              },
              {
                name: "Team",
                info: "Meet Core Team", RouteLink: "/teams"
              },
              {
                name: "News & Media",
                info: "Trendy articles, news and thoughts",
                RouteLink: "/news-media"
              },
              {
                name: "Data & Security",
                info: "Artivatic protects Data & information",
                RouteLink: "/data-security/1"

              },
              {
                name: "Customers",
                info: "Our Esteemed Customers",
                RouteLink: "/customers"
              },
              {
                name: "GDRP",
                info: "Ensuring Data Security and Privacy",
              },
              {
                name: "Contact Us",
                info: "We would love to hear from you", RouteLink: "/contact-us"
              },
              {
                name: "Careers",
                info: "Join the Artivatican Family",
                RouteLink: "/career"
              },
              {
                name: "Purchase Order",
                info: "Standard Terms & Conditions : Purchase Order",
                RouteLink: "/purchase-order"
              },
            ],
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
}
